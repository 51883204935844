import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  DoCheck,
} from '@angular/core';
import { FormValidatorService } from '../../../../../../shared/services/form-validator.service';
import { ViewProductInfoHandlerService } from '../../../../services/view-product-info-handler.service';
import { GetCalculatesParamsService } from '../../../../services/get-calculates-params.service';
import paymentOptionsConstant from '../../../../../../shared/paymentOptionsConstant';
import { PaymentNotifierService } from '../../container/buyer-info/PaymentNotifier.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FetchPAymentGroupForSeller } from 'src/app/shared/services/fetch-payment-groups-for-seller.service';
import { FetchGroupPaymentOptionService } from 'src/app/shared/services/fetch-group-payment-option-for-seller.service';
import { PaymentOptionsService } from 'src/app/public-website/buyer/services/payment-options.service';

@Component({
  selector: 'app-buyer-payment-options',
  templateUrl: './buyer-payment-options.component.html',
  styleUrls: ['./buyer-payment-options.component.scss'],
})
export class BuyerPaymentOptionsComponent implements OnInit, DoCheck {
  @Input() businessId: string;
  @Input() productId: any;
  @Input() isFreePayment: boolean;
  @Input() expired: any;
  @Input() totalAmount: number;
  @Input() checked: boolean;
  @Output() clickEvent = new EventEmitter();
  paymentGroups: Array<{ name: string; nameAr: string }> = [];
  options: any[] = [];
  formValidator: FormValidatorService;
  qassatlyLimit: number = 0;
  haveQassatly: boolean = false;
  selection: string = '';
  isCustomPayment: boolean = false;
  isValu: boolean = false;
  isSouhoola: boolean = false;
  isHalan: boolean = false;
  isMogo: boolean = false;
  nbeInstallments: string[] = [];
  bmInstallments: string[] = [];
  installments: string[] = [];
  selectedInstallment = '';

  constructor(
    public vpihs: ViewProductInfoHandlerService,
    private fetchPaymentGroups: FetchPAymentGroupForSeller,
    private fetchPaymentOptionForGroup: FetchGroupPaymentOptionService,
    public getCalculatesParamsService: GetCalculatesParamsService,
    public paymentNotifier: PaymentNotifierService,
    private authService: AuthService,
    private fetchAllPaymentGroups: PaymentOptionsService
  ) {
    this.formValidator = new FormValidatorService();
  }
  async ngOnInit() {
    if (
      this.vpihs.viewInfoObject &&
      this.vpihs.viewInfoObject.extras &&
      this.vpihs.viewInfoObject.extras.isCustomPayment
    ) {
      this.isCustomPayment = true;
      return;
    }
    if (this.isFreePayment) {
      this.fetchAllPaymentGroups.setParams({ id: this.businessId, isBuyer: true });
      this.paymentGroups = await this.fetchAllPaymentGroups.make();
    } else {
      this.fetchPaymentGroups.setParams({ productId: this.vpihs.productId });
      this.paymentGroups = await this.fetchPaymentGroups.make();
    }

    for(let i = 0; i < this.paymentGroups.length; i++) {
      if(this.paymentGroups[i].name == "Contact") {
        this.paymentGroups.splice(i, 1);
        break;
      }
    }

    const authBusunessId = await this.authService.getBusiness();
    this.haveQassatly = false;

    let installmentsPushed = false;

    for (let option of this.paymentGroups) {
      if (option.name == 'Qassatly') {
        this.haveQassatly = true;
      }
      //min amount for Valu is EGP 500
      if (option.name === 'ValU' || option.name === 'Souhoola' || option.name === 'Halan') {
        switch (option.name) {
          case 'ValU':
            this.isValu = true;
            break;
          case 'Souhoola':
            this.isSouhoola = true;
            break;
          case 'Halan':
            this.isHalan = true;
            break;
        }
        let total = this.totalAmount;
        if (total < 500) continue;

      }

      if (option.name === 'Mogo') {
        this.isMogo = true;
        if (this.totalAmount < 1000) continue;
      }

      if (option.name.includes('NBE')) {
        this.nbeInstallments.push(option.name);
        if (!installmentsPushed) {
          installmentsPushed = true;
          this.options.push('NBE Installments');
        }
        continue;
      }

      if (option.name.includes('BM')) {
        this.bmInstallments.push(option.name);
        if (!installmentsPushed) {
          installmentsPushed = true;
          this.options.push('BM Installments');
        }
        continue;
      }

      if (option.name.includes(' Months - Installments')) {
        this.installments.push(option.name);
        if (!installmentsPushed) {
          installmentsPushed = true;
          this.options.push('Installments');
        }
        continue;
      }

      installmentsPushed = false;
      this.options.push(option.name);
    }
    // handel offline payment item.
    if (
      (this.authService.isAuthenticated()) &&
      +this.businessId == authBusunessId.id &&
      !this.isFreePayment
    ) {
      this.paymentGroups = [
        {
          name: 'Offline Payments',
          nameAr: 'دفع اوفلاين',
        },
      ];
      this.options = [
        paymentOptionsConstant.constantPaymentOptions.name.OFFLINE_PAYMENT,
      ];
    }
  }
  ngDoCheck() {
    if (this.totalAmount >= 500) {
      if (
        this.paymentGroups.length > 0
      ) {
        if(!this.options.includes('ValU') && this.isValu) this.options.push('ValU');
        if(!this.options.includes('Souhoola') && this.isSouhoola) this.options.push('Souhoola');
        if(!this.options.includes('Halan') && this.isHalan) this.options.push('Halan');
      }
    }

    if (this.totalAmount >= 1000) {
      if (
        this.paymentGroups.length > 0
      ) {
        if(!this.options.includes('Mogo') && this.isMogo) this.options.push('Mogo');
      }
    }
  }
  async radioChange(event: any) {
    if (this.selectedInstallment === '' && !event.includes('Aman') && event.includes('Installments')) {
      this.selectedInstallment = event;
      return;
    }

    if (
      event ===
      paymentOptionsConstant.constantPaymentOptions.name.OFFLINE_PAYMENT
    ) {
      event = 'Offline Payments';
    }
    this.fetchPaymentOptionForGroup.setParams({
      groupName: event,
      productId: this.vpihs.productId,
      businessId: this.businessId,
    });
    const paymentOption = await this.fetchPaymentOptionForGroup.make();
    this.vpihs.setPaymentOptionObject({ selection: paymentOption.name });
    this.getCalculatesParamsService.setPaymentOptions(paymentOption.name);
    await this.getCalculatesParamsService.calculateInfos();
    this.qassatlyLimit = this.getCalculatesParamsService.limit;
    this.paymentNotifier.pushQassatlyNotification(
      paymentOption.name ==
        paymentOptionsConstant.constantPaymentOptions.value.QASSATLY
    );
    this.paymentNotifier.pushAmanNotification(
      paymentOption.name ==
        paymentOptionsConstant.constantPaymentOptions.value.Aman_Installments
    );

    this.clickEvent.emit();
  }

  resetInstallments() {
    this.selectedInstallment = '';
  }

  translateOption(index: number, options = this.options) {
    const option: string = options[index];
    if (document.body.classList.contains('dir-rtl')) {
      if (
        this.selectedInstallment === '' &&
        !option.includes('Aman') &&
        option.includes('Installments')
      ) {
        return this.translateInstallments(option, true);
      }
      return this.paymentGroups.find((p) => p.name === option).nameAr;
    }
    if (
      this.selectedInstallment === '' &&
      !option.includes('Aman') &&
      option.includes('Installments')
    ) {
      return this.translateInstallments(option);
    }
    return this.paymentGroups.find((p) => p.name === option).name;
  }

  private translateInstallments(option: string, rtl = false): string {
    if (option === 'NBE Installments') {
      return rtl ? 'أقساط البنك الأهلي المصري' : 'NBE Installments';
    } else if (option === 'BM Installments') {
      return rtl ? 'أقساط بنك مصر' : 'Banque Misr Installments';
    }
    return rtl ? 'نظم تقسيط' : 'Installments';
  }
}
